<template>
  <div class="formSignIn">
    <img
      src="/img/SL-LOGO-BLACK.svg"
      alt="Saint Laurent Logo"
      class="formSignIn__logo"
    />
    <h2 v-html="siteName" />
    <p class="subtitle">by anthony vaccarello</p>
    <form @submit="submit">
      <label for="password">
        <input
          id="password"
          type="password"
          name="password"
          v-model="password"
          autocomplete="new-password"
          placeholder="PASSWORD"
          focus
        />
      </label>
      <button type="submit">
        <Spinner v-show="submitting" />
        <Arrow v-show="!submitting" />
      </button>
    </form>
    <div class="formSignIn__error">
      <transition name="fade">
        <p v-show="error">PLEASE CHECK THAT YOU TYPED THE PASSWORD CORRECTLY</p>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// import fetcher from '@/assets/scripts/fetcher'
import Arrow from '@/components/svg/arrow.svg'
import Spinner from '@/components/element/spinner'
import useAuth from '@/useAuth'

export default {
  name: 'FormSignIn',
  components: {
    Spinner,
    Arrow
  },
  data() {
    return {
      error: false,
      submitting: false,
      password: null
    }
  },

  computed: {
    ...mapState({
      siteName: state => state.configuration.siteName
    })
  },

  mounted() {
    document.title =
      this.siteName
        .split('<br />')
        .join(' ')
        .toUpperCase() + ' - Saint Laurent'
  },

  methods: {
    ...mapMutations({
      setUserLoggedIn: 'setUserLoggedIn',
      setAssetsLoaded: 'setAssetsLoaded'
    }),
    async submit(e) {
      e.preventDefault()
      this.submitting = true
      try {
        const version = await this.$store.dispatch(
          'authenticate',
          this.password
        )
        this.onSuccess(version)
      } catch (err) {
        this.submitting = false
        this.onError()
      }
    },
    onSuccess(version) {
      this.error = false
      this.$router.push(`/${version.slug}`)
      useAuth().setLogin()
    },
    onError() {
      this.error = true
      this.setUserLoggedIn(false)
    }
  }
}
</script>

<style lang="scss">
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #666666;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #666666;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
}

.formSignIn {
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    max-width: max-content;
  }

  &__logo {
    height: auto;
    width: 261.44px;
    aspect-ratio: 261.44 / 58.68;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;

    @media screen and (min-width: 768px) {
      width: 428.31px;
      aspect-ratio: 428.31 / 96.36;
    }
  }

  h2 {
    margin-top: 10.15px;
    font-size: 17px;
    letter-spacing: -0.03em;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-family: 'Copperplate', sans-serif;
    text-align: center;
    white-space: nowrap;
    font-weight: 400;
    line-height: 0.6em;

    @media screen and (min-width: 768px) {
      font-size: 25px;
      margin-top: 17px;
    }
  }

  .subtitle {
    font-family: 'Copperplate', sans-serif;
    font-size: 11.56px;
    font-weight: 400;
    text-align: center;
    margin-top: 5.075px;
    line-height: 0.5em;

    @media screen and (min-width: 768px) {
      font-size: 18.38px;
      margin-top: 8.5px;
      letter-spacing: 0.2px;
    }
  }

  form {
    align-items: stretch;
    border-bottom: 1px solid var(--black);
    display: flex;
    max-width: 560px;
    margin-top: auto;

    label {
      flex-grow: 1;
    }

    input,
    button {
      background-color: transparent;
      border: unset;
    }

    input {
      color: var(--black);
      padding: 4px 0;
      font-size: 10px;
      line-height: 12px;
      width: 100%;
      font-family: 'HelveticaNeue', sans-serif;

      @media screen and (min-width: 768px) {
        font-size: 14px;
        line-height: 17px;
        padding: 7px 0;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
        -webkit-text-fill-color: var(--black) !important;
      }
    }

    button {
      flex-shrink: 0;
      padding-right: 0;
      width: 13px;
      padding: 0;

      > svg {
        //height: auto;
        //width: 100%;
      }

      @media screen and (min-width: 768px) {
        width: 24px;
      }
    }
  }

  p {
    color: var(black);
    font-size: 10px;
    margin: 0;
    line-height: 0.9em;
    //line-height: 12px;
    //letter-spacing: -0.01em;
    //margin-bottom: 0;
  }

  &__error {
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 768px) {
      font-size: 14px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    height: 14px;
  }
}
</style>
