<template>
  <div :class="{ 'page--maxWidth': maxWidth }" class="page">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.page {
  padding-left: var(--spacing-xxl);
  padding-top: var(--spacing-xl);
  padding-right: var(--spacing-xxl);
  padding-bottom: var(--spacing-xxl);
  width: 100%;
  max-width: 1200px;
  &--maxWidth {
    max-width: calc(var(--fixed-content-width) + 2 * var(--spacing-xxl));
  }
}
</style>
