<template>
  <b-page class="uploadMediaPage">
    <e-edit-header back-to="/admin/medias">Ajouter des medias</e-edit-header>
    <form
      ref="form"
      class="uploadMediaPage__form"
      @submit.prevent="uploadFiles"
    >
      <h2>Télécharger des fichiers</h2>
      <p>
        Sélectionner les images / vidéos que vous souhaitez ajouter dans la
        médiathèque.
      </p>
      <!-- <e-button
        @click.native="selectMediaFile"
        class="uploadMediaPage__selectButton"
        >Ajouter un media</e-button
      > -->
      <e-upload-button
        name="medias"
        types=".png,.jpg,.webp,.mp4,.webm"
        multiple
        @files="onFiles"
        class="uploadMediaPage__uploadButton"
      />
      <template v-if="pendingUploadFileList.length > 0">
        <h3>
          {{ pendingUploadFileList.length }} médias ajouté(s),
          {{ uploadCount }}/{{ pendingUploadFileList.length }} téléchargé(s).
        </h3>
        <media-editor
          ref="mediaList"
          v-if="pendingUploadFileList.length > 0"
          class="uploadMediaPage__editor"
        >
          <template v-slot:header>
            <e-button
              class="uploadMediaPage__saveButton"
              type="submit"
              :disabled="saved"
              >Enregistrer</e-button
            >
            <e-link
              class="uploadMediaPage__cancelButton"
              button
              to="/admin/medias"
              >Annuler</e-link
            >
          </template>
        </media-editor>
      </template>
    </form>
    <b-popin v-if="showCancelPrompt" @cancel="hideCancelPrompt">
      <prompt-media-cancel @confirm="leave" @cancel="hideCancelPrompt" />
    </b-popin>
    <b-popin v-if="showMediaFileSelection" @cancel="hideMediaSelection">
      <prompt-media-file-selection
        @confirm="onFiles"
        @cancel="hideMediaSelection"
      />
    </b-popin>
  </b-page>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import store from '../../store'
import MediaEditor from '../../components/block/media-editor'
import PromptMediaCancel from '../../components/elements/prompt-media-cancel'
import PromptMediaFileSelection from '../../components/block/prompt-media-file-selection'

export default {
  components: {
    MediaEditor,
    PromptMediaCancel,
    PromptMediaFileSelection
  },
  data() {
    return {
      showCancelPrompt: false,
      showMediaFileSelection: false,
      leaveRoute: null
    }
  },
  computed: {
    ...mapState({
      pendingUploadFileList: state => state.medias.pendingUploadFileList,
      uploadCount: state => state.medias.uploadCount
    }),
    saved() {
      return this.uploadCount === this.pendingUploadFileList.length
    },
    medias() {
      return this.$refs.mediaList.$refs.media
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('groups/fetch')
    store.commit('medias/resetUploadCount')
    next()
  },
  beforeRouteLeave(to, from, next) {
    this.showCancelPrompt = !this.saved
    if (this.showCancelPrompt) {
      this.leaveRoute = next
    } else {
      this.clearPendingUploadFileList()
      next()
    }
  },
  methods: {
    ...mapMutations({
      addPendingUploadFileList: 'medias/addPendingUploadFileList',
      clearPendingUploadFileList: 'medias/clearPendingUploadFileList'
    }),
    hideCancelPrompt() {
      this.showCancelPrompt = false
    },
    leave() {
      this.clearPendingUploadFileList()
      this.leaveRoute()
    },
    onFiles(files) {
      Array.from(files).forEach(file => {
        this.addPendingUploadFileList([file, null, null])
      })
    },
    uploadFiles() {
      this.medias.forEach(media => media.upload())
    },
    selectMediaFile() {
      this.showMediaFileSelection = true
    },
    hideMediaSelection() {
      this.showMediaFileSelection = false
    }
  }
}
</script>

<style lang="scss">
.uploadMediaPage {
  &__form {
    h2,
    h3,
    > p {
      margin-top: 0;
    }

    h2,
    h3 {
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 1em;
      font-size: 10px;
    }

    > p {
      text-transform: initial;
      letter-spacing: 0 !important;
      font-size: 12px;
      margin-bottom: 2em;
    }
  }

  &__uploadButton,
  &__editor {
    margin-bottom: var(--spacing-xl);
  }

  &__saveButton {
    margin-left: auto !important;
  }
}
</style>
